import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const pageStyles = css``;

const description = ``;

function Tags() {
  const { allTag, allServiceTag } = useStaticQuery(graphql`
    query {
      allTag {
        nodes {
          name
          count
          slug
        }
      }
      allServiceTag {
        nodes {
          name
          count
          slug
        }
      }
    }
  `);

  const allTags = [...allTag.nodes, ...allServiceTag.nodes].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Layout>
      <SEO description={description} title="Tag Index" />
      <section css={pageStyles}>
        <ul>
          {allTags.map(({ name, slug, count }) => (
            <li key={slug}>
              <Link to={"/tag/" + slug}>{name}</Link>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
}

export default Tags;
